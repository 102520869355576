$white: #D1D1D1;
$blue: #3973b3;
$red: #DA735F;
$green: #5fc563;
$black: #5d5957;
$yellow: #f3d055;

$colors: (
  "white": $white,
  "blue": $blue,
  "red": $red,
  "green": $green,
  "black": $black,
  "yellow": $yellow
);

.card {
  margin-top: 10px;
  overflow-x: scroll;
  background-color: #faf8d3;

  .row {
    display: flex;
    flex-direction: row;

    .cell {
      display: flex;
      padding: 5px;
      align-items: center;

      .dice {
        width: 50px;
        height: 50px;
        border: 2px solid;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        position: relative;

        .dice-value {
          position: absolute;
          left: 5px;
          bottom: 5px;
          font-size: 1rem;
        }

        @each $name, $color in $colors {
          &-#{$name} {
            border-color: $color;
            background-color: lighten($color, 30%);
            color: darken($color, 10%);
          }
        }

        &.missed {
          font-size: 0;
          background: linear-gradient(to top left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.8px), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) calc(50% + 0.8px), rgba(0, 0, 0, 0) 100%), linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.8px), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) calc(50% + 0.8px), rgba(0, 0, 0, 0) 100%);
        }
      }
    }

    .total {
      width: 75px;
      border: 1px solid;
      background-color: white;
      padding: 5px;
      text-align: center;
      border-radius: 5px;
    }
  }
}
